<template>
  <v-container>
    <!-- news -->

    <v-layout>
      <v-flex display-3 font-weight-light my-5 pt-3> .neues </v-flex>
    </v-layout>

    <v-container grid-list-lg pa-0 ma-0>
      <v-layout row wrap>
        <v-flex
          v-for="(newsEntry, i) in news"
          :key="i"
          @click="$router.push('neues/id' + i)"
          xs12
          sm6
          md4
          mb-4
        >
          <v-card flat tile>
            <v-img
              v-if="newsEntry.pics"
              :src="
                'https://sjr.widerstand.digital' +
                '/pics/' +
                newsEntry.organisation +
                '/news/' +
                newsEntry.pics[0]
              "
              height="150px"
            >
              <div class="preview-container">
                <div class="preview-title font-weight-light headline">
                  {{ newsEntry.title }}
                </div>
              </div>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- projekte -->

    <v-layout>
      <v-flex display-3 font-weight-light my-5 pt-3> .was wir machen </v-flex>
    </v-layout>

    <v-container grid-list-lg pa-0 ma-0>
      <v-layout row wrap>
        <v-flex @click="$router.push('projekte')" xs12 sm6 md4 mb-4>
          <v-card flat tile>
            <v-img :src="'/bgimg/projekte.jpg'" height="150px">
              <div class="preview-container">
                <div class="preview-title font-weight-light headline">
                  Zur Übersicht
                </div>
              </div>
            </v-img>
          </v-card>
        </v-flex>
        <v-flex
          @click="
            $router.push(
              'projekte/' + project.title.replace(/\s/g, '').split('.').join('')
            )
          "
          v-for="(project, i) in projects.slice(0, 5)"
          :key="i"
          xs12
          sm6
          md4
          mb-4
        >
          <v-card flat tile>
            <v-img :src="'/img/projekte/' + project.titleImage" height="150px">
              <div class="preview-container">
                <div class="preview-title font-weight-light headline">
                  {{ project.title }}
                </div>
              </div>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <!-- events -->

    <v-container class="px-0">
      <v-layout>
        <v-flex display-3 font-weight-light my-5 pt-3> .termine </v-flex>
      </v-layout>

      <v-layout column>
        <template v-if="events.length > 0">
          <v-flex v-for="(event, i) in events" :key="i" xs4 my-3>
            <v-card class="clickable">
              <v-card-title
                @click="$router.push('/termine/e' + event._id)"
                class="display-1 font-weight-light mt-1 pa-3"
                >{{ event.title }}</v-card-title
              >
              <v-card-text class="body-1 pl-3 pe-1 pt-0">
                <div
                  @click="$router.push('/termine/id' + event._id)"
                  class="font-weight-light font-italic"
                >
                  Wann:
                  <template
                    v-if="
                      $moment(event.date.start).isSame(event.date.end, 'day')
                    "
                  >
                    <span>
                      {{ $moment(event.date.start).format("dddd") }} der
                    </span>
                    <span
                      >{{ $moment(event.date.start).format("DD.MM.YY") }},
                    </span>
                    <span>
                      {{ $moment(event.time.start).format("HH:mm") }} -
                      {{ $moment(event.time.end).format("HH:mm") }}
                      Uhr</span
                    >
                  </template>
                  <template v-else>
                    <span>
                      <span
                        >{{ $moment(event.date.start).format("dddd") }} der
                      </span>
                      {{ $moment(event.date.start).format("DD.MM.YY") }}
                      {{ $moment(event.time.start).format("HH:mm") }} Uhr
                      <span class="card-text regular">bis </span>
                    </span>
                    <span>
                      <span>
                        {{ $moment(event.date.end).format("dddd") }} den
                      </span>
                      {{ $moment(event.date.end).format("DD.MM.YY") }}
                      {{ $moment(event.time.end).format("HH:mm") }}
                      Uhr
                    </span>
                  </template>
                </div>
                <div
                  @click="$router.push('/events/id' + event._id)"
                  class="font-weight-light font-italic"
                >
                  Wo: {{ event.location ? event.location : "tba" }}
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </template>
        <template v-else>
          <div class="display-1 font-weight-light">
            Aktuelle Termine findet ihr
            <a
              target="_blank"
              href="https://www.facebook.com/popbastion/events/"
              >HIER</a
            >.
          </div>
        </template>
      </v-layout>
    </v-container>

    <!-- spotify -->

    <v-container class="px-0">
      <v-layout>
        <v-flex display-3 font-weight-light my-5 pt-3> .musik </v-flex>
      </v-layout>

      <v-layout column>
        <v-flex>
          <div class="embed-container">
            <iframe
              :src="playlist"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["events", "news", "projects", "playlist"],
  mounted: function () {
    this.setBgImage("home");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
