<template>
  <v-container>
    <!-- headline -->

    <v-layout>
      <v-flex display-4 font-weight-thin my-5 pt-5> .kontakt </v-flex>
    </v-layout>

    <!-- team -->
    <v-layout>
      <v-flex xs12 my-5 id="team">
        <v-card>
          <v-img class="odd" src=""></v-img>
          <v-card-title
            :class="{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >Team</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            <v-container grid-list-lg pa-0 ma-0>
              <v-layout v-bind="binding" wrap>
                <v-flex
                  v-for="(member, i) in team"
                  :key="i"
                  xs1
                  v-bind="bindingTeam"
                >
                  <v-card flat tile>
                    <v-img
                      v-if="member.pic"
                      :src="
                        'https://sjr-bucket.s3.eu-central-1.amazonaws.com/' +
                        member.pic.name
                      "
                      :height="bindingHeight"
                    ></v-img>
                    <v-layout>
                      <v-flex mt-3 mb-5>
                        <div class="headline">{{ member.name }}</div>
                        <div v-if="member.email" v-html="member.email"></div>
                        <div v-if="member.phone" v-html="member.phone"></div>
                        <div
                          v-if="member.department"
                          v-html="member.department"
                        ></div>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- address -->

    <v-layout>
      <v-flex id="adresse" xs12 my-5>
        <v-card>
          <v-card-title
            :class="{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'pt-5': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >Anschrift und Telefon</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'font-weight-light': true,
              'pb-5': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            popbastion.ulm<br />
            Schillerstraße 1/4<br />
            89077 Ulm<br />
            <br />
            Telefon: +49 (0) 73 1 140 69 20<br />
            Telefax: +49 (0) 73 1 140 69 69<br />
            <br />
            info@popbastion.de
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- form -->

    <v-layout column>
      <v-flex id="formular" xs12 my-5>
        <v-card>
          <v-card-title
            :class="{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'pt-5': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >Kontakt-Formular</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'font-weight-light': true,
              'pb-5': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            <v-form v-if="!isSent" v-model="valid" color="white">
              <v-layout v-bind="binding">
                <v-flex mr-2 xs12 sm6>
                  <v-text-field
                    v-model="name"
                    label="Dein Name"
                    :rules="[(v) => !!v || 'Bitte gib deinen Namen an.']"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="email"
                    label="Deine Emailadresse"
                    validate-on-blur
                    :rules="[
                      (v) => !!v || 'Bitte gib eine gültige Email-Adresse an.',
                      (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(
                          v
                        ) || 'E- mail must be valid',
                    ]"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-text-field
                v-model="subject"
                label="Betreff"
                :rules="[
                  (v) => !!v || 'Bitte gibt deiner Nachricht einen Betreff.',
                ]"
              >
              </v-text-field>
              <v-textarea
                v-model="message"
                label="Deine Nachricht an uns"
                :rules="[
                  (v) => !!v || 'Wolltest du uns nicht etwas Mitteilen?',
                ]"
              >
              </v-textarea>
              <v-checkbox
                v-model="accept"
                color="primary"
                label="Ich habe die Datenschutz-Erklärung gelesen und akzeptiere diese."
                :rules="[
                  (v) =>
                    !!v ||
                    'Bitte lies und akzeptiere die Datenschutzbedingungen.',
                ]"
              ></v-checkbox>
              <v-btn
                :disabled="!valid"
                @click="prepareMail()"
                large
                block
                color="primary"
              >
                Absenden
              </v-btn>
            </v-form>
            <v-layout v-if="isSent">
              <v-flex>
                {{ feedbackMessage }}
                <br /><br />
                <v-btn @click="resetMail()" large block color="primary">
                  Weitere Mail schreiben
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex id="impressum" xs12 my-5>
        <v-card>
          <v-card-title
            :class="{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'pt-5': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >Impressum</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'font-weight-light': true,
              'pb-5': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div class="subheading">Angaben gemäß § 5 TMG:</div>
            <br />
            Stadtjugendring Ulm e.V.<br />
            popbastion.ulm<br />
            Schillerstraße 1/4<br />
            89077 Ulm<br /><br />
            <div class="subheading">Vertreten durch:</div>
            <br />
            Georg Baier<br />
            Kathrin Przewodnik<br />
            Lucia Geitmann<br /><br />
            <div class="subheading">Kontakt:</div>
            <br />
            Telefon: 0731 – 140 69 20<br />
            Telefax: 0731 – 140 69 69<br />
            E-Mail: info@popbastion.de<br /><br />
            <div class="subheading">
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            </div>
            <br />
            popbastion.ulm<br />
            Maren Donners<br />
            Schillerstraße 1/4<br />
            89077 Ulm<br /><br />
            <div class="subheading">Fotografie</div>
            <br />
            Die Fotografien auf dieser Webseite sind von:<br />
            <ul>
              <li>Diana Mühlberger</li>
              <li>Jens Kramer</li>
              <li>Cameron Kirby</li>
              <li>Julien Sister</li>
              <li>Marco Krenn</li>
              <li>Seth Doyle</li>
              <li>popbastion.ulm</li>
            </ul>
            <br />
            <div class="subheading">Hinweis auf EU-Streitschlichtung</div>
            <br />
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr<br />
            Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am
            Streitbeilegungsverfahren im Rahmen der Europäischen
            Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur
            Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer.<br /><br />
            <div class="subheading">Haftungsausschluss</div>
            <br />
            Haftung für Inhalte<br /><br />
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.<br /><br />
            Haftung für Links<br /><br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.<br /><br />
            <div class="subheading">Urheberrecht</div>
            <br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.<br /><br />
            Quelle: Disclaimer von eRecht24, dem Portal zum Internetrecht von
            Rechtsanwalt Sören Siebert http://www.e-recht24.de, Impressum
            Generator
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex id="datenschutz" xs12 my-5>
        <v-card>
          <v-card-title
            :class="{
              'display-1': true,
              'font-weight-light': true,
              'mt-4': true,
              'pt-5': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
            >Datenschutz</v-card-title
          >
          <v-card-text
            :class="{
              'body-1': true,
              'font-weight-light': true,
              'pb-5': true,
              'px-0': $vuetify.breakpoint.xsAndDown,
              'px-5': $vuetify.breakpoint.smAndUp,
            }"
          >
            Wir freuen uns sehr über Ihr Interesse an unserem Verein.
            Datenschutz hat einen besonders hohen Stellenwert für die Leitung
            des Stadtjugendrings e. V. / popbastion.ulm, Betreiber der Webseite
            von www.popbastion.de. Eine Nutzung der Internetseiten des
            Stadtjugendrings e. V. / popbastion.ulm ist grundsätzlich ohne jede
            Angabe personenbezogener Daten möglich. Sofern eine betroffene
            Person besondere Services unseres Unternehmens über unsere
            Internetseite in Anspruch nehmen möchte, könnte jedoch eine
            Verarbeitung personenbezogener Daten erforderlich werden. Ist die
            Verarbeitung personenbezogener Daten erforderlich und besteht für
            eine solche Verarbeitung keine gesetzliche Grundlage, holen wir
            generell eine Einwilligung der betroffenen Person ein.<br /><br />
            Die Verarbeitung personenbezogener Daten, beispielsweise des Namens,
            der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
            Person, erfolgt stets im Einklang mit der
            Datenschutz-Grundverordnung und in Übereinstimmung mit den für den
            Stadtjugendring e. V. geltenden landesspezifischen
            Datenschutzbestimmungen. Mittels dieser Datenschutz-Erklärung möchte
            unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der
            von uns erhobenen, genutzten und verarbeiteten personenbezogenen
            Daten informieren. Ferner werden betroffene Personen mittels dieser
            Datenschutz-Erklärung über die ihnen zustehenden Rechte
            aufgeklärt.<br /><br />
            Der Stadtjugendring e. V. / popbastion.ulm hat als für die
            Verarbeitung Verantwortlicher zahlreiche technische und
            organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen
            Schutz der über diese Internetseite verarbeiteten personenbezogenen
            Daten sicherzustellen. Dennoch können Internetbasierte
            Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass
            ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem
            Grund steht es jeder betroffenen Person frei, personenbezogene Daten
            auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu
            übermitteln.<br /><br />
            <div class="subheading">Begriffsbestimmungen</div>
            <br />
            Die Datenschutz-Erklärung des Stadtjugendring e. V. / popbastion.ulm
            beruht auf den Begrifflichkeiten, die durch den Europäischen
            Richtlinien- und Verordnungsgeber beim Erlass der
            Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
            Datenschutz-Erklärung soll sowohl für die Öffentlichkeit als auch
            für unsere Kunden und Geschäftspartner einfach lesbar und
            verständlich sein. Um dies zu gewährleisten, möchten wir vorab die
            verwendeten Begrifflichkeiten erläutern.<br /><br />
            Wir verwenden in dieser Datenschutz-Erklärung unter anderem die
            folgenden Begriffe:<br /><br />
            a) Personenbezogene Daten sind alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person (im Folgenden
            „betroffene Person“) beziehen. Als identifizierbar wird eine
            natürliche Person angesehen, die direkt oder indirekt, insbesondere
            mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
            Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
            oder mehreren besonderen Merkmalen, die Ausdruck der physischen,
            physiologischen, genetischen, psychischen, wirtschaftlichen,
            kulturellen oder sozialen Identität dieser natürlichen Person sind,
            identifiziert werden kann.<br /><br />
            b) Betroffene Person ist jede identifizierte oder identifizierbare
            natürliche Person, deren personenbezogene Daten von dem für die
            Verarbeitung Verantwortlichen verarbeitet werden.<br /><br />
            c) Verarbeitung ist jeder mit oder ohne Hilfe automatisierter
            Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
            Zusammenhang mit personenbezogenen Daten wie das Erheben, das
            Erfassen, die Organisation, das Ordnen, die Speicherung, die
            Anpassung oder Veränderung, das Auslesen, das Abfragen, die
            Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
            eine andere Form der Bereitstellung, den Abgleich oder die
            Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.<br /><br />
            d) Einschränkung der Verarbeitung ist die Markierung gespeicherter
            personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
            einzuschränken.<br /><br />
            e) Profiling ist jede Art der automatisierten Verarbeitung
            personenbezogener Daten, die darin besteht, dass diese
            personenbezogenen Daten verwendet werden, um bestimmte persönliche
            Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
            insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher
            Lage, Gesundheit, persönlicher Vorlieben, Interessen,
            Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
            natürlichen Person zu analysieren oder vorherzusagen.<br /><br />
            f) Pseudonymisierung ist die Verarbeitung personenbezogener Daten in
            einer Weise, auf welche die personenbezogenen Daten ohne
            Hinzuziehung zusätzlicher Informationen nicht mehr einer
            spezifischen betroffenen Person zugeordnet werden können, sofern
            diese zusätzlichen Informationen gesondert aufbewahrt werden und
            technischen und organisatorischen Maßnahmen unterliegen, die
            gewährleisten, dass die personenbezogenen Daten nicht einer
            identifizierten oder identifizierbaren natürlichen Person zugewiesen
            werden.<br /><br />
            g) Verantwortlicher oder für die Verarbeitung Verantwortlicher ist
            die natürliche oder juristische Person, Behörde, Einrichtung oder
            andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke
            und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
            Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht
            oder das Recht der Mitgliedstaaten vorgegeben, so kann der
            Verantwortliche beziehungsweise können die bestimmten Kriterien
            seiner Benennung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten vorgesehen werden.<br /><br />
            h) Auftragsverarbeiter ist eine natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten
            im Auftrag des Verantwortlichen verarbeitet.<br /><br />
            i) Empfänger ist eine natürliche oder juristische Person, Behörde,
            Einrichtung oder andere Stelle, der personenbezogene Daten
            offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
            Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten
            Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten möglicherweise personenbezogene Daten erhalten,
            gelten jedoch nicht als Empfänger.<br /><br />
            j) Dritter ist eine natürliche oder juristische Person, Behörde,
            Einrichtung oder andere Stelle außer der betroffenen Person, dem
            Verantwortlichen, dem Auftragsverarbeiter und den Personen, die
            unter der unmittelbaren Verantwortung des Verantwortlichen oder des
            Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
            verarbeiten.<br /><br />
            k) Einwilligung ist jede von der betroffenen Person freiwillig für
            den bestimmten Fall in informierter Weise und unmissverständlich
            abgegebene Willensbekundung in Form einer Erklärung oder einer
            sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
            Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
            betreffenden personenbezogenen Daten einverstanden ist.<br /><br />
            <div class="subheading">
              Name und Anschrift des für die Verarbeitung Verantwortlichen:
            </div>
            <br />
            Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger
            in den Mitgliedstaaten der Europäischen Union geltenden
            Datenschutzgesetze und anderer Bestimmungen mit
            datenschutzrechtlichem Charakter ist:<br /><br />
            popbastion.ulm<br />
            Stadtjugendring Ulm e. V.<br />
            Schillerstr. 1/4<br />
            89077 Ulm<br />
            Tel.: 0731 – 140 691<br />
            E-Mail: info@popbastion.de<br />
            Website: www.popbastion.de<br /><br />
            <div class="subheading">Cookies</div>
            <br />
            Die Internetseiten der Stadtjugendring Ulm e. V. verwenden Cookies.
            Cookies sind Textdateien, welche über einen Internetbrowser auf
            einem Computersystem abgelegt und gespeichert werden. Zahlreiche
            Internetseiten und Server verwenden Cookies. Viele Cookies enthalten
            eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige
            Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch
            welche Internetseiten und Server dem konkreten Internetbrowser
            zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies
            ermöglicht es den besuchten Internetseiten und Servern, den
            individuellen Browser der betroffenen Person von anderen
            Internetbrowsern, die andere Cookies enthalten, zu unterscheiden.
            Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID
            wiedererkannt und identifiziert werden.<br /><br />
            Durch den Einsatz von Cookies kann der Stadtjugendring Ulm e. V. /
            popbastion.ulm den Nutzern dieser Internetseite nutzerfreundlichere
            Services bereitstellen, die ohne die Cookie-Setzung nicht möglich
            wären.<br /><br />
            Mittels eines Cookies können die Informationen und Angebote auf
            unserer Internetseite im Sinne des Benutzers optimiert werden.
            Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer unserer
            Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es,
            den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der
            Benutzer einer Internetseite, die Cookies verwendet, muss
            beispielsweise nicht bei jedem Besuch der Internetseite erneut seine
            Zugangsdaten eingeben, weil dies von der Internetseite und dem auf
            dem Computersystem des Benutzers abgelegten Cookie übernommen wird.
            Ein weiteres Beispiel ist das Cookie eines Warenkorbes im
            Online-Shop. Der Online-Shop merkt sich die Artikel, die ein Kunde
            in den virtuellen Warenkorb gelegt hat, über ein Cookie.<br /><br />
            Die betroffene Person kann die Setzung von Cookies durch unsere
            Internetseite jederzeit mittels einer entsprechenden Einstellung des
            genutzten Internetbrowsers verhindern und damit der Setzung von
            Cookies dauerhaft widersprechen. Ferner können bereits gesetzte
            Cookies jederzeit über einen Internetbrowser oder andere
            Softwareprogramme gelöscht werden. Dies ist in allen gängigen
            Internetbrowsern möglich. Deaktiviert die betroffene Person die
            Setzung von Cookies in dem genutzten Internetbrowser, sind unter
            Umständen nicht alle Funktionen unserer Internetseite vollumfänglich
            nutzbar.<br /><br />
            <div class="subheading">
              Erfassung von allgemeinen Daten und Informationen
            </div>
            <br />
            Die Internetseite des Stadtjugendring Ulm e. V. erfasst mit jedem
            Aufruf der Internetseite durch eine betroffene Person oder ein
            automatisiertes System eine Reihe von allgemeinen Daten und
            Informationen. Diese allgemeinen Daten und Informationen werden in
            den Logfiles des Servers gespeichert. Erfasst werden können die (1)
            verwendeten Browsertypen und Versionen, (2) das vom zugreifenden
            System verwendete Betriebssystem, (3) die Internetseite, von welcher
            ein zugreifendes System auf unsere Internetseite gelangt (sogenannte
            Referrer), (4) die Unterwebseiten, welche über ein zugreifendes
            System auf unserer Internetseite angesteuert werden, (5) das Datum
            und die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine
            Internet-Protokoll-Adresse (IP-Adresse), (7) der
            Internet-Service-Provider des zugreifenden Systems und (8) sonstige
            ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle
            von Angriffen auf unsere informationstechnologischen Systeme
            dienen.<br /><br />
            Bei der Nutzung dieser allgemeinen Daten und Informationen zieht der
            Stadtjugendring Ulm e. V. keine Rückschlüsse auf die betroffene
            Person. Diese Informationen werden vielmehr benötigt, um (1) die
            Inhalte unserer Internetseite korrekt auszuliefern, (2) die Inhalte
            unserer Internetseite sowie die Werbung für diese zu optimieren, (3)
            die dauerhafte Funktionsfähigkeit unserer
            informationstechnologischen Systeme und der Technik unserer
            Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden
            im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen
            Informationen bereitzustellen. Diese anonym erhobenen Daten und
            Informationen werden durch Stadtjugendring Ulm e. V. daher
            einerseits statistisch und ferner mit dem Ziel ausgewertet, den
            Datenschutz und die Datensicherheit in unserem Unternehmen zu
            erhöhen, um letztlich ein optimales Schutzniveau für die von uns
            verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen
            Daten der Server-Logfiles werden getrennt von allen durch eine
            betroffene Person angegebenen personenbezogenen Daten
            gespeichert.<br /><br />
            <div class="subheading">
              Registrierung auf unserer Internetseite
            </div>
            <br />
            Die betroffene Person hat die Möglichkeit, sich auf der
            Internetseite des für die Verarbeitung Verantwortlichen unter Angabe
            von personenbezogenen Daten zu registrieren. Welche
            personenbezogenen Daten dabei an den für die Verarbeitung
            Verantwortlichen übermittelt werden, ergibt sich aus der jeweiligen
            Eingabemaske, die für die Registrierung verwendet wird. Die von der
            betroffenen Person eingegebenen personenbezogenen Daten werden
            ausschließlich für die interne Verwendung bei dem für die
            Verarbeitung Verantwortlichen und für eigene Zwecke erhoben und
            gespeichert. Der für die Verarbeitung Verantwortliche kann die
            Weitergabe an einen oder mehrere Auftragsverarbeiter, beispielsweise
            einen Paketdienstleister, veranlassen, der die personenbezogenen
            Daten ebenfalls ausschließlich für eine interne Verwendung, die dem
            für die Verarbeitung Verantwortlichen zuzurechnen ist, nutzt. Durch
            eine Registrierung auf der Internetseite des für die Verarbeitung
            Verantwortlichen wird ferner die vom Internet-Service-Provider (ISP)
            der betroffenen Person vergebene IP-Adresse, das Datum sowie die
            Uhrzeit der Registrierung gespeichert. Die Speicherung dieser Daten
            erfolgt vor dem Hintergrund, dass nur so der Missbrauch unserer
            Dienste verhindert werden kann, und diese Daten im Bedarfsfall
            ermöglichen, begangene Straftaten aufzuklären. Insofern ist die
            Speicherung dieser Daten zur Absicherung des für die Verarbeitung
            Verantwortlichen erforderlich. Eine Weitergabe dieser Daten an
            Dritte erfolgt grundsätzlich nicht, sofern keine gesetzliche Pflicht
            zur Weitergabe besteht oder die Weitergabe der Strafverfolgung
            dient. Die Registrierung der betroffenen Person unter freiwilliger
            Angabe personenbezogener Daten dient dem für die Verarbeitung
            Verantwortlichen dazu, der betroffenen Person Inhalte oder
            Leistungen anzubieten, die aufgrund der Natur der Sache nur
            registrierten Benutzern angeboten werden können. Registrierten
            Personen steht die Möglichkeit frei, die bei der Registrierung
            angegebenen personenbezogenen Daten jederzeit abzuändern oder
            vollständig aus dem Datenbestand des für die Verarbeitung
            Verantwortlichen löschen zu lassen. Der für die Verarbeitung
            Verantwortliche erteilt jeder betroffenen Person jederzeit auf
            Anfrage Auskunft darüber, welche personenbezogenen Daten über die
            betroffene Person gespeichert sind. Ferner berichtigt oder löscht
            der für die Verarbeitung Verantwortliche personenbezogene Daten auf
            Wunsch oder Hinweis der betroffenen Person, soweit dem keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen. Die Gesamtheit
            der Mitarbeiter des für die Verarbeitung Verantwortlichen stehen der
            betroffenen Person in diesem Zusammenhang als Ansprechpartner*innen
            zur Verfügung.<br /><br />
            <div class="subheading">
              Kontaktmöglichkeit über die Internetseite
            </div>
            <br />
            Die Internetseite des Stadtjugendrings Ulm e. V enthält aufgrund von
            gesetzlichen Vorschriften Angaben, die eine schnelle elektronische
            Kontaktaufnahme zu unserem Unternehmen sowie eine unmittelbare
            Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine
            Adresse der sogenannten elektronischen Post (E-Mail-Adresse)
            umfasst. Sofern eine betroffene Person per E-Mail oder über ein
            Kontaktformular den Kontakt mit dem für die Verarbeitung
            Verantwortlichen aufnimmt, werden die von der betroffenen Person
            übermittelten personenbezogenen Daten automatisch gespeichert.
            Solche auf freiwilliger Basis von einer betroffenen Person an den
            für die Verarbeitung Verantwortlichen übermittelten
            personenbezogenen Daten werden für Zwecke der Bearbeitung oder der
            Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine
            Weitergabe dieser personenbezogenen Daten an Dritte.<br /><br />
            <div class="subheading">
              Routinemäßige Löschung und Sperrung von personenbezogenen Daten
            </div>
            <br />
            Der für die Verarbeitung Verantwortliche verarbeitet und speichert
            personenbezogene Daten der betroffenen Person nur für den Zeitraum,
            der zur Erreichung des Speicherungszwecks erforderlich ist oder
            sofern dies durch den Europäischen Richtlinien- und Verordnungsgeber
            oder einen anderen Gesetzgeber in Gesetzen oder Vorschriften,
            welchen der für die Verarbeitung Verantwortliche unterliegt,
            vorgesehen wurde.<br /><br />
            Entfällt der Speicherungszweck oder läuft eine vom Europäischen
            Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
            Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
            personenbezogenen Daten routinemäßig und entsprechend den
            gesetzlichen Vorschriften gesperrt oder gelöscht.<br /><br />
            <div class="subheading">Rechte der betroffenen Person</div>
            <br />
            a) Jede betroffene Person hat das vom Europäischen Richtlinien- und
            Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung
            Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie
            betreffende personenbezogene Daten verarbeitet werden. Möchte eine
            betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann
            sie sich hierzu jederzeit an einen Mitarbeiter des für die
            Verarbeitung Verantwortlichen wenden.<br /><br />
            b) Jede von der Verarbeitung personenbezogener Daten betroffene
            Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
            gewährte Recht, jederzeit von dem für die Verarbeitung
            Verantwortlichen unentgeltliche Auskunft über die zu seiner Person
            gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft
            zu erhalten. Ferner hat der Europäische Richtlinien- und
            Verordnungsgeber der betroffenen Person Auskunft über folgende
            Informationen zugestanden:<br />
            die Verarbeitungszwecke<br />
            die Kategorien personenbezogener Daten, die verarbeitet werden<br />
            die Empfänger oder Kategorien von Empfängern, gegenüber denen die
            personenbezogenen Daten offengelegt worden sind oder noch
            offengelegt werden, insbesondere bei Empfängern in Drittländern oder
            bei internationalen Organisationen<br />
            falls möglich die geplante Dauer, für die die personenbezogenen
            Daten gespeichert werden, oder, falls dies nicht möglich ist, die
            Kriterien für die Festlegung dieser Dauer<br />
            das Bestehen eines Rechts auf Berichtigung oder Löschung der sie
            betreffenden personenbezogenen Daten oder auf Einschränkung der
            Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung<br />
            das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde<br />
            wenn die personenbezogenen Daten nicht bei der betroffenen Person
            erhoben werden: Alle verfügbaren Informationen über die Herkunft der
            Daten<br />
            das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DS-GVO und —
            zumindest in diesen Fällen — aussagekräftige Informationen über die
            involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene
            Person<br />
            Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu,
            ob personenbezogene Daten an ein Drittland oder an eine
            internationale Organisation übermittelt wurden. Sofern dies der Fall
            ist, so steht der betroffenen Person im Übrigen das Recht zu,
            Auskunft über die geeigneten Garantien im Zusammenhang mit der
            Übermittlung zu erhalten. Möchte eine betroffene Person dieses
            Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an
            einen Mitarbeiter des für die Verarbeitung Verantwortlichen
            wenden.<br /><br />
            c) Jede von der Verarbeitung personenbezogener Daten betroffene
            Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
            gewährte Recht, die unverzügliche Berichtigung sie betreffender
            unrichtiger personenbezogener Daten zu verlangen. Ferner steht der
            betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke
            der Verarbeitung, die Vervollständigung unvollständiger
            personenbezogener Daten — auch mittels einer ergänzenden Erklärung —
            zu verlangen. Möchte eine betroffene Person dieses
            Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu
            jederzeit an einen Mitarbeiter des für die Verarbeitung
            Verantwortlichen wenden.<br /><br />
            d) Recht auf Löschung (Recht auf Vergessen werden). Jede von der
            Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von
            dem Verantwortlichen zu verlangen, dass die sie betreffenden
            personenbezogenen Daten unverzüglich gelöscht werden, sofern einer
            der folgenden Gründe zutrifft und soweit die Verarbeitung nicht
            erforderlich ist:<br />
            Die personenbezogenen Daten wurden für solche Zwecke erhoben oder
            auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig
            sind.<br />
            Die betroffene Person widerruft ihre Einwilligung, auf die sich die
            Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs.
            2 Buchstabe a DS-GVO stützte, und es fehlt an einer anderweitigen
            Rechtsgrundlage für die Verarbeitung.<br />
            Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch
            gegen die Verarbeitung ein, und es liegen keine vorrangigen
            berechtigten Gründe für die Verarbeitung vor, oder die betroffene
            Person legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen die
            Verarbeitung ein.<br />
            Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.<br />
            Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.<br />
            Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste
            der Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.<br />
            Sofern einer der oben genannten Gründe zutrifft und eine betroffene
            Person die Löschung von personenbezogenen Daten, die bei der
            Stadtjugendrings e. V. / popbastion.ulm gespeichert sind,
            veranlassen möchte, kann sie sich hierzu jederzeit an einen
            Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der
            Mitarbeiter des Stadtjugendring Ulm e. V. wird veranlassen, dass dem
            Löschverlangen unverzüglich nachgekommen wird. Wurden die
            personenbezogenen Daten vom Stadtjugendring Ulm e. V. öffentlich
            gemacht und ist unser Unternehmen als Verantwortlicher gemäß Art. 17
            Abs. 1 DS-GVO zur Löschung der personenbezogenen Daten verpflichtet,
            so trifft der Stadtjugendring Ulm e. V. unter Berücksichtigung der
            verfügbaren Technologie und der Implementierungskosten angemessene
            Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung
            Verantwortliche, welche die veröffentlichten personenbezogenen Daten
            verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene
            Person von diesen anderen für die Datenverarbeitung Verantwortlichen
            die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder
            von Kopien oder Replikationen dieser personenbezogenen Daten
            verlangt hat, soweit die Verarbeitung nicht erforderlich ist. Der
            Mitarbeiter des Stadtjugendring Ulm e. V. wird im Einzelfall das
            Notwendige veranlassen.<br /><br />
            e) Recht auf Einschränkung der Verarbeitung. Jede von der
            Verarbeitung personenbezogener Daten betroffene Person hat das vom
            Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von
            dem Verantwortlichen die Einschränkung der Verarbeitung zu
            verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:<br />
            Die Richtigkeit der personenbezogenen Daten wird von der betroffenen
            Person bestritten, und zwar für eine Dauer, die es dem
            Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen
            Daten zu überprüfen.<br />
            Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die
            Löschung der personenbezogenen Daten ab und verlangt stattdessen die
            Einschränkung der Nutzung der personenbezogenen Daten.<br />
            Der Verantwortliche benötigt die personenbezogenen Daten für die
            Zwecke der Verarbeitung nicht länger, die betroffene Person benötigt
            sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.<br />
            Die betroffene Person hat Widerspruch gegen die Verarbeitung gem.
            Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch nicht fest, ob die
            berechtigten Gründe des Verantwortlichen gegenüber denen der
            betroffenen Person überwiegen.<br />
            Sofern eine der oben genannten Voraussetzungen gegeben ist und eine
            betroffene Person die Einschränkung von personenbezogenen Daten, die
            beim Stadtjugendring Ulm e. V. gespeichert sind, verlangen möchte,
            kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
            Verarbeitung Verantwortlichen wenden. Der Mitarbeiter des
            Stadtjugendring Ulm e. V. wird die Einschränkung der Verarbeitung
            veranlassen.<br /><br />
            f) Recht auf Datenübertragbarkeit. Jede von der Verarbeitung
            personenbezogener Daten betroffene Person hat das vom Europäischen
            Richtlinien- und Verordnungsgeber gewährte Recht, die sie
            betreffenden personenbezogenen Daten, welche durch die betroffene
            Person einem Verantwortlichen bereitgestellt wurden, in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
            Sie hat außerdem das Recht, diese Daten einem anderen
            Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
            die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
            sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1
            Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf
            einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die
            Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die
            Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich
            ist, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen
            wurde. Ferner hat die betroffene Person bei der Ausübung ihres
            Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das
            Recht, zu erwirken, dass die personenbezogenen Daten direkt von
            einem Verantwortlichen an einen anderen Verantwortlichen übermittelt
            werden, soweit dies technisch machbar ist und sofern hiervon nicht
            die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
            Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die
            betroffene Person jederzeit an einen Mitarbeiter des Stadtjugendring
            Ulm e. V. wenden.<br /><br />
            g) Jede von der Verarbeitung personenbezogener Daten betroffene
            Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
            gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation
            ergeben, jederzeit gegen die Verarbeitung sie betreffender
            personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e
            oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch für
            ein auf diese Bestimmungen gestütztes Profiling. Der Stadtjugendring
            Ulm e. V. verarbeitet die personenbezogenen Daten im Falle des
            Widerspruchs nicht mehr, es sei denn, wir können zwingende
            schutzwürdige Gründe für die Verarbeitung nachweisen, die den
            Interessen, Rechten und Freiheiten der betroffenen Person
            überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung
            oder Verteidigung von Rechtsansprüchen. Verarbeitet der
            Stadtjugendring Ulm e. V. personenbezogene Daten, um Direktwerbung
            zu betreiben, so hat die betroffene Person das Recht, jederzeit
            Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum
            Zwecke derartiger Werbung einzulegen. Dies gilt auch für das
            Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
            Widerspricht die betroffene Person gegenüber des Stadtjugendring Ulm
            e. V. der Verarbeitung für Zwecke der Direktwerbung, so wird der
            Stadtjugendring Ulm e. V. die personenbezogenen Daten nicht mehr für
            diese Zwecke verarbeiten. Zudem hat die betroffene Person das Recht,
            aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen
            die sie betreffende Verarbeitung personenbezogener Daten, die beim
            Stadtjugendring Ulm e. V. zu wissenschaftlichen oder historischen
            Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1
            DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
            Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
            liegenden Aufgabe erforderlich. Zur Ausübung des Rechts auf
            Widerspruch kann sich die betroffene Person direkt jeden Mitarbeiter
            des Stadtjugendring Ulm e. V. oder einen anderen Mitarbeiter wenden.
            Der betroffenen Person steht es ferner frei, im Zusammenhang mit der
            Nutzung von Diensten der Informationsgesellschaft, ungeachtet der
            Richtlinie 2002/58/EG, ihr Widerspruchsrecht mittels automatisierter
            Verfahren auszuüben, bei denen technische Spezifikationen verwendet
            werden.<br /><br />
            h) Automatisierte Entscheidungen im Einzelfall einschließlich
            Profiling. Jede von der Verarbeitung personenbezogener Daten
            betroffene Person hat das vom Europäischen Richtlinien- und
            Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf
            einer automatisierten Verarbeitung — einschließlich Profiling —
            beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber
            rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich
            beeinträchtigt, sofern die Entscheidung (1) nicht für den Abschluss
            oder die Erfüllung eines Vertrags zwischen der betroffenen Person
            und dem Verantwortlichen erforderlich ist, oder (2) aufgrund von
            Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
            Verantwortliche unterliegt, zulässig ist und diese
            Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und
            Freiheiten sowie der berechtigten Interessen der betroffenen Person
            enthalten oder (3) mit ausdrücklicher Einwilligung der betroffenen
            Person erfolgt. Ist die Entscheidung (1) für den Abschluss oder die
            Erfüllung eines Vertrags zwischen der betroffenen Person und dem
            Verantwortlichen erforderlich oder (2) erfolgt sie mit
            ausdrücklicher Einwilligung der betroffenen Person, trifft der
            Stadtjugendring Ulm e. V. angemessene Maßnahmen, um die Rechte und
            Freiheiten sowie die berechtigten Interessen der betroffenen Person
            zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens
            einer Person seitens des Verantwortlichen, auf Darlegung des eigenen
            Standpunkts und auf Anfechtung der Entscheidung gehört. Möchte die
            betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen
            geltend machen, kann sie sich hierzu jederzeit an einen Mitarbeiter
            des für die Verarbeitung Verantwortlichen wenden.<br /><br />
            i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung.
            Jede von der Verarbeitung personenbezogener Daten betroffene Person
            hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte
            Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten
            jederzeit zu widerrufen. Möchte die betroffene Person ihr Recht auf
            Widerruf einer Einwilligung geltend machen, kann sie sich hierzu
            jederzeit an einen Mitarbeiter des für die Verarbeitung
            Verantwortlichen wenden.<br /><br />
            <div class="subheading">
              Datenschutzbestimmungen zu Einsatz und Verwendung von Google
              Analytics (mit Anonymisierungsfunktion):
            </div>
            <br />
            Der für die Verarbeitung Verantwortliche hat auf dieser
            Internetseite die Komponente Google Analytics (mit
            Anonymisierungsfunktion) integriert. Google Analytics ist ein
            Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und
            Auswertung von Daten über das Verhalten von Besuchern von
            Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem Daten
            darüber, von welcher Internetseite eine betroffene Person auf eine
            Internetseite gekommen ist (sogenannte Referrer), auf welche
            Unterseiten der Internetseite zugegriffen oder wie oft und für
            welche Verweildauer eine Unterseite betrachtet wurde. Eine
            Web-Analyse wird überwiegend zur Optimierung einer Internetseite und
            zur Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.<br /><br />
            Betreibergesellschaft der Google-Analytics-Komponente ist die Google
            Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.<br />
            Der für die Verarbeitung Verantwortliche verwendet für die
            Web-Analyse über Google Analytics den Zusatz „_gat._anonymizeIp“.
            Mittels dieses Zusatzes wird die IP-Adresse des Internetanschlusses
            der betroffenen Person von Google gekürzt und anonymisiert, wenn der
            Zugriff auf unsere Internetseiten aus einem Mitgliedstaat der
            Europäischen Union oder aus einem anderen Vertragsstaat des
            Abkommens über den Europäischen Wirtschaftsraum erfolgt.<br />
            Der Zweck der Google-Analytics-Komponente ist die Analyse der
            Besucherströme auf unserer Internetseite. Google nutzt die
            gewonnenen Daten und Informationen unter anderem dazu, die Nutzung
            unserer Internetseite auszuwerten, um für uns Online-Reports, welche
            die Aktivitäten auf unseren Internetseiten aufzeigen,
            zusammenzustellen, und um weitere mit der Nutzung unserer
            Internetseite in Verbindung stehende Dienstleistungen zu
            erbringen.<br /><br />
            Google Analytics setzt ein Cookie auf dem
            informationstechnologischen System der betroffenen Person. Was
            Cookies sind, wurde oben bereits erläutert. Mit Setzung des Cookies
            wird Google eine Analyse der Benutzung unserer Internetseite
            ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser
            Internetseite, die durch den für die Verarbeitung Verantwortlichen
            betrieben wird und auf welcher eine Google-Analytics-Komponente
            integriert wurde, wird der Internetbrowser auf dem
            informationstechnologischen System der betroffenen Person
            automatisch durch die jeweilige Google-Analytics-Komponente
            veranlasst, Daten zum Zwecke der Online-Analyse an Google zu
            übermitteln. Im Rahmen dieses technischen Verfahrens erhält Google
            Kenntnis über personenbezogene Daten, wie der IP-Adresse der
            betroffenen Person, die Google unter anderem dazu dienen, die
            Herkunft der Besucher und Klicks nachzuvollziehen und in der Folge
            Provisionsabrechnungen zu ermöglichen.<br />
            Mittels des Cookies werden personenbezogene Informationen,
            beispielsweise die Zugriffszeit, der Ort, von welchem ein Zugriff
            ausging und die Häufigkeit der Besuche unserer Internetseite durch
            die betroffene Person, gespeichert. Bei jedem Besuch unserer
            Internetseiten werden diese personenbezogenen Daten, einschließlich
            der IP-Adresse des von der betroffenen Person genutzten
            Internetanschlusses, an Google in den Vereinigten Staaten von
            Amerika übertragen. Diese personenbezogenen Daten werden durch
            Google in den Vereinigten Staaten von Amerika gespeichert. Google
            gibt diese über das technische Verfahren erhobenen personenbezogenen
            Daten unter Umständen an Dritte weiter.<br />
            Die betroffene Person kann die Setzung von Cookies durch unsere
            Internetseite, wie oben bereits dargestellt, jederzeit mittels einer
            entsprechenden Einstellung des genutzten Internetbrowsers verhindern
            und damit der Setzung von Cookies dauerhaft widersprechen. Eine
            solche Einstellung des genutzten Internetbrowsers würde auch
            verhindern, dass Google ein Cookie auf dem
            informationstechnologischen System der betroffenen Person setzt.
            Zudem kann ein von Google Analytics bereits gesetzter Cookie
            jederzeit über den Internetbrowser oder andere Softwareprogramme
            gelöscht werden.<br />
            Ferner besteht für die betroffene Person die Möglichkeit, einer
            Erfassung der durch Google Analytics erzeugten, auf eine Nutzung
            dieser Internetseite bezogenen Daten sowie der Verarbeitung dieser
            Daten durch Google zu widersprechen und eine solche zu verhindern.
            Hierzu muss die betroffene Person ein Browser-Add-On unter dem Link
            https://tools.google.com/dlpage/gaoptout herunterladen und
            installieren. Dieses Browser-Add-On teilt Google Analytics über
            JavaScript mit, dass keine Daten und Informationen zu den Besuchen
            von Internetseiten an Google Analytics übermittelt werden dürfen.
            Die Installation des Browser-Add-Ons wird von Google als Widerspruch
            gewertet. Wird das informationstechnologische System der betroffenen
            Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu
            installiert, muss durch die betroffene Person eine erneute
            Installation des Browser-Add-Ons erfolgen, um Google Analytics zu
            deaktivieren. Sofern das Browser-Add-On durch die betroffene Person
            oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist,
            deinstalliert oder deaktiviert wird, besteht die Möglichkeit der
            Neuinstallation oder der erneuten Aktivierung des
            Browser-Add-Ons.<br />
            Weitere Informationen und die geltenden Datenschutzbestimmungen von
            Google können unter https://www.google.de/intl/de/policies/privacy/
            und unter http://www.google.com/analytics/terms/de.html abgerufen
            werden. Google Analytics wird unter diesem Link
            https://www.google.com/intl/de_de/analytics/ genauer erläutert.<br /><br />
            <div class="subheading">Rechtsgrundlage der Verarbeitung</div>
            <br />
            Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage
            für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen
            bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung
            personenbezogener Daten zur Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, erforderlich, wie dies
            beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine
            Lieferung von Waren oder die Erbringung einer sonstigen Leistung
            oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf
            Art. 6 I lit. b DS-GVO. Gleiches gilt für solche
            Verarbeitungsvorgänge die zur Durchführung vorvertraglicher
            Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren
            Produkten oder Leistungen. Unterliegt unser Unternehmen einer
            rechtlichen Verpflichtung durch welche eine Verarbeitung von
            personenbezogenen Daten erforderlich wird, wie beispielsweise zur
            Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf
            Art. 6 I lit. c DS-GVO. In seltenen Fällen könnte die Verarbeitung
            von personenbezogenen Daten erforderlich werden, um lebenswichtige
            Interessen der betroffenen Person oder einer anderen natürlichen
            Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein
            Besucher in unserem Betrieb verletzt werden würde und daraufhin sein
            Name, sein Alter, seine Krankenkassendaten oder sonstige
            lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder
            sonstige Dritte weitergegeben werden müssten. Dann würde die
            Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich könnten
            Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO beruhen. Auf dieser
            Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der
            vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung
            zur Wahrung eines berechtigten Interesses unseres Unternehmens oder
            eines Dritten erforderlich ist, sofern die Interessen, Grundrechte
            und Grundfreiheiten des Betroffenen nicht überwiegen. Solche
            Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil
            sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er
            vertrat insoweit die Auffassung, dass ein berechtigtes Interesse
            anzunehmen sein könnte, wenn die betroffene Person ein Kunde des
            Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).<br /><br />
            <div class="subheading">
              Berechtigte Interessen an der Verarbeitung, die von dem
              Verantwortlichen oder einem Dritten verfolgt werden:
            </div>
            <br />
            Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I
            lit. f DS-GVO ist unser berechtigtes Interesse die Durchführung
            unserer Geschäftstätigkeit zugunsten des Wohlergehens all unserer
            Mitarbeiter und unserer Anteilseigner.<br /><br />
            <div class="subheading">
              Dauer, für die die personenbezogenen Daten gespeichert werden
            </div>
            <br />
            Das Kriterium für die Dauer der Speicherung von personenbezogenen
            Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf
            der Frist werden die entsprechenden Daten routinemäßig gelöscht,
            sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung
            erforderlich sind.<br /><br />
            <div class="subheading">
              Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
              personenbezogenen Daten; Erforderlichkeit für den
              Vertragsabschluss
            </div>
            <br />
            Verpflichtung der betroffenen Person, die personenbezogenen Daten
            bereitzustellen; mögliche Folgen der Nichtbereitstellung. Wir klären
            Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum
            Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder
            sich auch aus vertraglichen Regelungen (z.B. Angaben zum
            Vertragspartner) ergeben kann. Mitunter kann es zu einem
            Vertragsschluss erforderlich sein, dass eine betroffene Person uns
            personenbezogene Daten zur Verfügung stellt, die in der Folge durch
            uns verarbeitet werden müssen. Die betroffene Person ist
            beispielsweise verpflichtet uns personenbezogene Daten
            bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag
            abschließt. Eine Nichtbereitstellung der personenbezogenen Daten
            hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht
            geschlossen werden könnte. Vor einer Bereitstellung
            personenbezogener Daten durch den Betroffenen muss sich der
            Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter
            klärt den Betroffenen einzelfallbezogen darüber auf, ob die
            Bereitstellung der personenbezogenen Daten gesetzlich oder
            vertraglich vorgeschrieben oder für den Vertragsabschluss
            erforderlich ist, ob eine Verpflichtung besteht, die
            personenbezogenen Daten bereitzustellen, und welche Folgen die
            Nichtbereitstellung der personenbezogenen Daten hätte.<br /><br />
            <div class="subheading">
              Bestehen einer automatisierten Entscheidungsfindung
            </div>
            <br />
            Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
            automatische Entscheidungsfindung oder ein Profiling.<br /><br />
            Diese Datenschutz-Erklärung wurde durch den
            Datenschutz-Erklärungs-Generator der DGD Deutsche Gesellschaft für
            Datenschutz GmbH, die als Externer Datenschutzbeauftragter München
            tätig ist, in Kooperation mit der RC GmbH, die gebrauchte Computer
            wiederverwertet und der Kanzlei WILDE BEUGER SOLMECKE |
            Rechtsanwälte erstellt.
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      valid: false,
      name: "",
      email: "",
      subject: "",
      message: "",
      accept: false,
      feedbackMessage: "",
      isSent: false,
    };
  },
  props: ["team"],
  mounted: function () {
    this.setBgImage(this.$route.name);
    if (this.$route.params.id) {
      this.scrollToElement(this.$route.params.id);
    }
  },
  methods: {
    resetMail() {
      this.name = "";
      this.email = "";
      this.subject = "";
      this.message = "";
      this.accept = false;
      this.isSent = false;
      this.feedbackMessage = "";
    },
    prepareMail() {
      const content = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
      };
      this.sendMail(content)
        .then((response) => {
          this.isSent = true;
          if (response.data === false) {
            this.feedbackMessage =
              "Ohhhh nooo. Das hat leider nicht geklappt. Bitte versuche es erneut, oder wende dich direkt an die oben angegebene Adresse.";
          } else {
            this.feedbackMessage =
              "Vielen Dank für die Nachricht. Wir melden uns in Kürze.";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {
    binding() {
      const binding = {};
      if (this.$vuetify.breakpoint.smAndUp) {
        binding.row = true;
      } else {
        binding.column = true;
      }
      return binding;
    },
    bindingHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "200px";
      } else {
        return "350px";
      }
    },
    bindingTeam() {
      const binding = {};
      if (this.team.length < 3) {
        binding.sm6 = true;
        binding.md6 = true;
        binding.lg6 = true;
      } else {
        binding.sm4 = true;
        binding.md4 = true;
        binding.lg4 = true;
      }
      return binding;
    },
  },
};
</script>

<style scoped></style>
