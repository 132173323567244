var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"display-3":"","font-weight-light":"","my-5":"","pt-3":""}},[_vm._v(" .neues ")])],1),_c('v-container',{attrs:{"grid-list-lg":"","pa-0":"","ma-0":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.news),function(newsEntry,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":"","md4":"","mb-4":""},on:{"click":function($event){return _vm.$router.push('neues/id' + i)}}},[_c('v-card',{attrs:{"flat":"","tile":""}},[(newsEntry.pics)?_c('v-img',{attrs:{"src":'https://sjr.widerstand.digital' +
              '/pics/' +
              newsEntry.organisation +
              '/news/' +
              newsEntry.pics[0],"height":"150px"}},[_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"preview-title font-weight-light headline"},[_vm._v(" "+_vm._s(newsEntry.title)+" ")])])]):_vm._e()],1)],1)}),1)],1),_c('v-layout',[_c('v-flex',{attrs:{"display-3":"","font-weight-light":"","my-5":"","pt-3":""}},[_vm._v(" .was wir machen ")])],1),_c('v-container',{attrs:{"grid-list-lg":"","pa-0":"","ma-0":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","mb-4":""},on:{"click":function($event){return _vm.$router.push('projekte')}}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-img',{attrs:{"src":'/bgimg/projekte.jpg',"height":"150px"}},[_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"preview-title font-weight-light headline"},[_vm._v(" Zur Übersicht ")])])])],1)],1),_vm._l((_vm.projects.slice(0, 5)),function(project,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":"","md4":"","mb-4":""},on:{"click":function($event){_vm.$router.push(
            'projekte/' + project.title.replace(/\s/g, '').split('.').join('')
          )}}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-img',{attrs:{"src":'/img/projekte/' + project.titleImage,"height":"150px"}},[_c('div',{staticClass:"preview-container"},[_c('div',{staticClass:"preview-title font-weight-light headline"},[_vm._v(" "+_vm._s(project.title)+" ")])])])],1)],1)})],2)],1),_c('v-container',{staticClass:"px-0"},[_c('v-layout',[_c('v-flex',{attrs:{"display-3":"","font-weight-light":"","my-5":"","pt-3":""}},[_vm._v(" .termine ")])],1),_c('v-layout',{attrs:{"column":""}},[(_vm.events.length > 0)?_vm._l((_vm.events),function(event,i){return _c('v-flex',{key:i,attrs:{"xs4":"","my-3":""}},[_c('v-card',{staticClass:"clickable"},[_c('v-card-title',{staticClass:"display-1 font-weight-light mt-1 pa-3",on:{"click":function($event){return _vm.$router.push('/termine/e' + event._id)}}},[_vm._v(_vm._s(event.title))]),_c('v-card-text',{staticClass:"body-1 pl-3 pe-1 pt-0"},[_c('div',{staticClass:"font-weight-light font-italic",on:{"click":function($event){return _vm.$router.push('/termine/id' + event._id)}}},[_vm._v(" Wann: "),(
                    _vm.$moment(event.date.start).isSame(event.date.end, 'day')
                  )?[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(event.date.start).format("dddd"))+" der ")]),_c('span',[_vm._v(_vm._s(_vm.$moment(event.date.start).format("DD.MM.YY"))+", ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$moment(event.time.start).format("HH:mm"))+" - "+_vm._s(_vm.$moment(event.time.end).format("HH:mm"))+" Uhr")])]:[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$moment(event.date.start).format("dddd"))+" der ")]),_vm._v(" "+_vm._s(_vm.$moment(event.date.start).format("DD.MM.YY"))+" "+_vm._s(_vm.$moment(event.time.start).format("HH:mm"))+" Uhr "),_c('span',{staticClass:"card-text regular"},[_vm._v("bis ")])]),_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.$moment(event.date.end).format("dddd"))+" den ")]),_vm._v(" "+_vm._s(_vm.$moment(event.date.end).format("DD.MM.YY"))+" "+_vm._s(_vm.$moment(event.time.end).format("HH:mm"))+" Uhr ")])]],2),_c('div',{staticClass:"font-weight-light font-italic",on:{"click":function($event){return _vm.$router.push('/events/id' + event._id)}}},[_vm._v(" Wo: "+_vm._s(event.location ? event.location : "tba")+" ")])])],1)],1)}):[_c('div',{staticClass:"display-1 font-weight-light"},[_vm._v(" Aktuelle Termine findet ihr "),_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/popbastion/events/"}},[_vm._v("HIER")]),_vm._v(". ")])]],2)],1),_c('v-container',{staticClass:"px-0"},[_c('v-layout',[_c('v-flex',{attrs:{"display-3":"","font-weight-light":"","my-5":"","pt-3":""}},[_vm._v(" .musik ")])],1),_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[_c('div',{staticClass:"embed-container"},[_c('iframe',{attrs:{"src":_vm.playlist,"frameborder":"0","allowtransparency":"true","allow":"encrypted-media"}})])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }